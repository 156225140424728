<template>
	<div>
		<H5header></H5header>
		<div class="lun" v-if="cards && cards.length > 0">
			<carousel :imgList="cards" @getclick="getdetails" :width="width" height="209PX" :type="6"
				background="rgba(0, 0, 0, 0.5)"></carousel>
		</div>
		<div class="advertisement-box" v-if="adv1.length > 0 && adv1[0]">
			<carousel :imgList="adv1" :width="(width - 50)" height="67PX" :type="4" borderradius="5PX" @getclickm="getclickm"></carousel>
		</div>
		<div class="nav">
			<div v-for="(item,index) in menuItems" :key="index" class="navbut" @click="getnav(item,index + 1)"
				:style="{background: navIndex == index + 1 ? '#DEF2FE':''}">
				<img :src="item.imgUrl" alt="">
				<p>{{item.name}}</p>
			</div>
		</div>
		<div class="zicd" :style="{'display': navIndex != '' ? 'flex':'none'}">
			<div v-for="(item,index) in navDate" :key="index" class="zicdtitle" @click="getzicd(index + 1,item)"
				:style="{color: zicdIndex == index + 1 ? '#1975F0':''}">
				{{item.name}}
			</div>
		</div>
		<div class="overlay" :style="{'display': navIndex != '' ? 'block':'none'}" @click="getoverlay"></div>
		<div v-if="allData && allData[0] && allData[0].length > 0">
			<div class="article" v-for="(item,index) in allData[0]" :key="index">
				<div class="articlebox">
					<div class="zuo">
						<img src="./image/article.png" alt="">
						<p>{{item.name}}</p>
					</div>
					<div class="you" @click="jump(item)">
						<p>查看全部</p>
						<img src="./image/repair.png" alt="">
					</div>
				</div>
				<div class="articlelist" v-for="(item1,index1) in item.articles" :key="index1"
					@click="getdetails(item1.id)">
					<div class="title">{{item1.title}}</div>
					<div class="date">{{item1.publishTime | format}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import H5header from "./components/header.vue"
	import carousel from '@/components/carousel.vue';
	export default {
		name: '',
		components: {
			H5header,
			carousel
		},
		data() {
			return {
				cards: [],
				adv1: [],
				adv2: [],
				menuItems: [],
				allData: [],
				navDate: [],
				navIndex: 0,
				zicdIndex: 0
			}
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		computed: {
			width() {
				return Number(document.documentElement.clientWidth)
			}
		},
		created() {
			this.getIndexImgList()
			this.getBanner()
			this.getNav()
			this.getIndexColumn()
			this.$getwxsdk('北京市体育竞赛管理和国际交流中心')
		},
		activated() {
			this.$store.commit("EXIT_STATIONID",'1005012')
		},
		methods: {
			getIndexImgList() {
				this.$api.getIndexImgList().then(res => {
					this.cards = res.data.data
				})
			},
			//获取轮播数据
			getBanner() {
				this.$api.getTopBanner({
					type: "2"
				}).then(res => {
					res.data.data.forEach(item => {
						this.adv1.push(item)
					})
				})
			},
			getNav() {
				this.$api.getNav().then(res => {
					res.data.data.forEach(item => {
						res.url = '#'
						if (item.name == '综合资讯') {
							let obj = {
								id: 739,
								parentId: 843,
								name: '培训课堂'
							}
							item.children.splice(4, 0, obj)
						}
					})
					this.menuItems = res.data.data
				})
			},
			getIndexColumn() {
				this.$api.getIndexColumn(this.$store.state.stationId).then(res => {
					this.allData = res.data.data
					this.allData[0].forEach(item=>{
						item.articles.forEach(err=>{
							err.title = err.title.replace(/<br>/g, ' ')
						})
					})
				})
			},
			jump() {

			},
			getnav(item, index) {
				this.zicdIndex = ''
				if (item.name == '北京竞技场') {
					this.$router.push({
						path: '/h5/H5special',
						query: {
							id: item.id,
							name: item.name,
							refresh: new Date().getTime(),
						}
					})
				} else {
					this.navDate = item.children
					this.navIndex = index
				}
			},
			getzicd(index, item) {
				if (item.name == '赛事登记') {
					if (this.$store.state.token == '') {
						this.$store.commit("EXIT_TOKEN")
						this.$router.push({
							path: '/h5/login',
							query: {
								person: false,
								refresh: new Date().getTime()
							}
						})
						return
					}
					if (this.$store.state.userType == 'user_type_01') {
						this.$confirm('当前账号为个人账号，是否切换成团体账号', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$store.commit("EXIT_TOKEN")
							this.$router.push({
								path: '/h5/login',
								query: {
									person: false,
									refresh: new Date().getTime()
								}
							})
						}).catch(() => {});

						return

					}
					this.$api.getTimeStamp().then(ess=>{
						let data = {
							accessToken: this.$store.state.token,
							redirectURL: '/sportMatch/SportMatchList',
							timestampe:ess.data.data.timestamp
						}
						this.$api.getCode(data).then(err => {
							let url = process.env.VUE_APP_BASE_API_ADMIN + '/tokenLogin?code=' + err.data.data.result.code
							window.open(url, '_self');
						})
					})
				} else {
					if(item.name == '精彩瞬间'){
						let newurl = item.linkAddress.replace("notice", "h5/H5notice");
						window.open(newurl, '_self')
					}else{
						this.$router.push({
							path: '/h5/H5notice',
							query: {
								id: item.id,
								pid: item.parentId,
								name: item.name,
								refresh: new Date().getTime(),
							}
						})
					}
				}
				this.zicdIndex = index
				this.navIndex = ''
				this.zicdIndex = ''
			},
			jump(item) {
				this.$router.push({
					path: '/h5/H5notice',
					query: {
						id: item.id,
						pid: item.parentId,
						refresh: new Date().getTime(),
					}
				});
			},
			getoverlay() {
				this.navIndex = ''
			},
			getdetails(id) {
				this.$router.push({
					path: '/h5/H5articledetails',
					query: {
						id: id,
						refresh: new Date().getTime(),
					}
				})
			},
			getclickm(row) {
				if (row.skipUrl == '111') {
					if (this.$store.state.token == '') {
						this.$store.commit("EXIT_TOKEN")
						this.$router.push({
							path: '/h5/login',
							query: {
								person: false,
								grp: true,
								refresh: new Date().getTime()
							}
						})
						return
					}
					
					if (this.$store.state.userType == 'user_type_01') {
						this.$confirm('当前账号为个人账号，是否切换成团体账号', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$store.commit("EXIT_TOKEN")
							this.$router.push({
								path: '/h5/login',
								query: {
									person: false,
									grp: true,
									refresh: new Date().getTime()
								}
							})
						}).catch(() => {});
						return
					}
					
					this.$api.getTimeStamp().then(ess => {
						let data = {
							accessToken: this.$store.state.token,
							redirectURL: '/sportMatch/SportMatchList',
							timestampe: ess.data.data.timestamp
						}
						this.$api.getCode(data).then(err => {
							let url = process.env.VUE_APP_BASE_API_ADMIN + '/tokenLogin?code=' + err.data
								.data.result.code
							window.open(url, '_self');
						})
					})
					
				} else if (row.skipUrl && row.skipUrl != '111') {
					// var a = row.skipUrl
					// var targetURL = 'https://www.bjcac.org.cn/';
					// if (a.startsWith(targetURL)) {
					//   var modifiedURL = targetURL + 'h5/'+ a.substring(targetURL.length);
					//   console.log(modifiedURL);
					//   window.open(modifiedURL, '_blank');
					// } else {
					//   console.log(a);
					//   window.open(a, '_blank');
					// }
					 window.open(row.skipUrl, '_blank')
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	.advertisement-box {
		margin-top: 12PX;
		padding: 0 25PX;
		border-radius: 50%;

		::v-deep .SwiperBox {
			width: 100% !important;
		}

		::v-deep .instBox_back {
			width: 100% !important;
		}
	}

	.nav {
		display: flex;
		flex-wrap: wrap;
		padding: 12PX 19PX 0 18PX;
		justify-content: space-between;
		margin-bottom: 6PX;

		.navbut {
			width: 30%;
			height: 72PX;
			background: #f6f6f6;
			border-radius: 10PX;
			margin-right: 10PX;
			margin-bottom: 11PX;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;

			img {
				width: 24PX;
				height: 24PX;
				margin-top: 11PX;
			}

			p {
				font-size: 15PX;
				font-family: Microsoft YaHei;
				font-weight: 400;
				margin-top: 5PX;
			}
		}

		.navbut:nth-child(3n) {
			margin-right: 0;
		}
	}

	.zicd {
		display: flex;
		flex-wrap: wrap;
		margin: 17PX 0;
		border-top: 1PX solid rgba(220, 220, 220, 0.42);
		padding: 17PX 20PX 0 20PX;

		.zicdtitle {
			height: 28PX;
			background: rgba(246, 246, 246, 0.66);
			opacity: 0.66;
			font-size: 12PX;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 13PX;
			padding: 0 10PX;
			margin-bottom: 10PX;
		}

		.zicdtitle:nth-child(4n) {
			margin-right: 0;
		}
	}

	.overlay {
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1;
	}

	.article {
		.articlebox {
			width: 100%;
			height: 34PX;
			background: #FAFAFA;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 24PX;
			// border-bottom: 1PX solid rgba(0, 0, 0, 0.22);
			border-top: 1PX solid rgba(0, 0, 0, 0.22);

			.zuo {
				display: flex;
				align-items: center;

				img {
					width: 12PX;
					height: 14PX;
				}

				p {
					font-size: 14PX;
					font-family: Source Han Sans CN;
					font-weight: bold;
					margin-left: 9PX;
				}
			}

			.you {
				display: flex;
				align-items: center;

				img {
					width: 5PX;
					height: 9PX;
				}

				p {
					font-size: 11PX;
					font-family: Microsoft YaHei;
					font-weight: 400;
					margin-right: 8PX;
				}
			}
		}

		.articlelist {
			border-top: 1PX solid rgba(220, 220, 220, 1);

			.title {
				height: 36PX;
				font-size: 14PX;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin: 17PX 22PX 0 22PX;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			.date {
				font-size: 11PX;
				font-family: Source Han Sans CN;
				font-weight: 400;
				margin-top: 5PX;
				margin-right: 23PX;
				text-align: right;
				margin-bottom: 12PX;
			}
		}
	}
</style>
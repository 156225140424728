<template>
	<div>
		<div class="head">
			<div class="header-box">
				<div class="logoback">
					<img src="../../../assets/image/logo.png" alt="">
				</div>
				<div class="input">
					<img src="../image/searchfor.png" alt="">
					<input type="text" placeholder="请输入您想查询的赛事" v-model="keyword" @keyup.enter="submit" @focus="getfocus">
					<div class="sous" @click.stop="submit" v-if="show">搜索</div>
				</div>

				<el-dropdown trigger="click">
					<span class="el-dropdown-link">
						<img src="../image/more.png" alt="" class="more">
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item><a href="/h5">首 页</a></el-dropdown-item>
						<el-dropdown-item v-if="this.$store.state.token != ''">
							<div @click="determine" v-if="this.$store.state.token != '' && this.$store.state.userType == 'user_type_01'">
								<span>欢迎您,</span>
								<span class="roundtitle" style="color: #0047A5;" v-if="this.$store.state.realnameornot.requeststatus == 1 || this.$store.state.realnameornot.caStatus == 1">{{this.$store.state.realnameornot.realname}}</span>
								<span class="roundtitle" style="color: #0047A5;" v-else>{{this.$store.state.user.phone}}</span>
							</div>
							<div @click="determine" v-if="this.$store.state.token != '' && this.$store.state.userType == 'user_type_02'">
								<span>欢迎您,</span>
								<span class="roundtitle" style="color: #0047A5;" v-if="groupdate.requeststatus == 1">{{groupdate.name}}</span>
								<span class="roundtitle" style="color: #0047A5;" v-else>{{this.$store.state.user.phone}}</span>
							</div>
						</el-dropdown-item>
						<el-dropdown-item>
							<div v-if="this.$store.state.token == ''" @click="center">
								用户中心
							</div>
						</el-dropdown-item>
						<el-dropdown-item>
							<div v-if="this.$store.state.token == ''">
								<a href="/h5/register">账号注册</a>
							</div>
						</el-dropdown-item>
						<el-dropdown-item>
							<div v-if="this.$store.state.token != ''" @click="exit">
								退 出
							</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>


	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				keyword: '',
				show:false,
				groupdate:{}
			}
		},
		created() {
			if(this.$store.state.userType == 'user_type_02' && this.$store.state.token != ''){
				this.getCommunityAuthenInfo()
			}
			if(this.$store.state.userType == 'user_type_01' && this.$store.state.token != ''){
				this.$store.commit("EXIT_REALN")
			}
		},
		methods: {
			getCommunityAuthenInfo(){
				this.$api.getCommunityAuthenInfo({userid:this.$store.state.userid}).then(res=>{
					this.groupdate = res.data.data.result
				})
			},
			exit() {
				this.$api.checkLogout({
					accessToken: this.$store.state.token
				}).then(res => {
					this.$message.success(res.data.data.message);
					setTimeout(() => {
						this.$store.commit("EXIT_TOKEN")
						this.$router.push({
							path: '/h5/login'
						})
					}, 500)
				})
			},
			center() {
				if (this.$store.state.token == '') {
					this.$router.push({
						path: '/h5/login'
					})
				} else {
					this.$router.push({
						path: '/h5/register'
					})
				}
			},
			determine() {
				if (this.$store.state.userType == 'user_type_01') {
					this.$router.push({
						path: '/h5/registerIndex',
						query: {
							refresh: new Date().getTime()
						},
					})
				} else {
					this.$api.getTimeStamp().then(ess=>{
						let data = {
							accessToken: this.$store.state.token,
							redirectURL: '/authIndex',
							timestampe:ess.data.data.timestamp
						}
						this.$api.getCode(data).then(err => {
							let url = process.env.VUE_APP_BASE_API_ADMIN + '/tokenLogin?code=' + err.data.data.result.code
							window.open(url, '_self');
						})
					})
				}
			},
			submit() {
				this.$router.push({
					path: '/h5/search',
					query: {
						keyword: this.keyword,
						refresh: new Date().getTime()
					},
				})
				this.show = false
			},
			getfocus(){
				this.show = true
				
			},
			getblur(){
				this.show = false
				console.log(2)
			}
		}
	}
</script>

<style scoped lang="scss">
	.head {
		width: 100%;
		height: 120PX;
		background: url('../image/head_back.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.header-box {
			display: flex;
			justify-content: space-between;

			.logoback {
				width: 36PX;
				height: 36PX;
				border-radius: 50%;
				background: #fff;
				margin-top: 70PX;
				margin-left: 14PX;
				flex-shrink: 0;
				padding: 5PX;
			}
		}

		.input {
			width: 69%;
			height: 36PX;
			background: #FFFDFD;
			border-radius: 18PX;
			margin-top: 70PX;
			margin-left: 14PX;
			display: flex;
			align-items: center;


			img {
				width: 15PX;
				height: 15PX;
				margin-left: 31PX;
				margin-right: 15PX;
			}

			input {
				width: 77%;
				height: 36PX;
				border-radius: 18PX;
				font-size: 12PX;
			}

			.sous {
				flex-shrink: 0;
				color: #fff;
				height: 26PX;
				padding: 0 15PX;
				border-radius: 20PX;
				background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
				margin-right: 5PX;
				line-height: 26PX;
			}
		}

		.more {
			width: 16PX;
			height: 3PX;
			// margin-top: 88PX;
			margin-left: 12PX;
			margin-right: 17PX;
		}

		.el-dropdown-link{
			position: relative;
			top: 79PX;
		}
	}
</style>